import React, { useEffect, useState } from 'react';
import './App.css';

const API_BASE_URL = 'https://nav-backend.di39.com';

function App() {
  // 设置初始状态，包括临时数据
  const [carouselItems, setCarouselItems] = useState([
    { src: '/img/carousel/default.png', alt: 'default Image 1' },
    { src: '/img/carousel/default.png', alt: 'default Image 2' },
    { src: '/img/carousel/default.png', alt: 'default Image 3' }
  ]);
  const [dramaItems, setDramaItems] = useState([
    { icon: '📺', link: '#', text: '这里还什么都没有呢...' },
    { icon: '📻', link: '#', text: '试着耐心等待一会...' }
  ]);
  const [lifeItems, setLifeItems] = useState([
    { icon: '💟', link: 'https://u.jd.com/FbbbUkd', text: '每天领京东红包' },
    { icon: '🏷', link: '#', text: '生活不止面包，还有诗和远方' },
    { icon: '👜', link: '#', text: '试着耐心等待一会...' }
  ]);

  useEffect(() => {
    fetchCarouselData();
    fetchDramaData();
    fetchLifeData();
  }, []);

  const fetchCarouselData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/carousel`);
      const data = await response.json();
      // 过滤掉没有 src 属性的项
      const validData = data.filter(item => item && item.src);
      // 如果返回的数据为空数组，则使用临时数据
      setCarouselItems(validData.length > 0 ? validData : carouselItems);
    } catch (error) {
      console.error('Error fetching carousel data:', error);
    }
  };

  const fetchDramaData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/drama`);
      const data = await response.json();
      // 如果返回的数据为空数组，则使用临时数据
      setDramaItems(Array.isArray(data) && data.length > 0 ? data : dramaItems);
    } catch (error) {
      console.error('Error fetching drama data:', error);
    }
  };

  const fetchLifeData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/life`);
      const data = await response.json();
      // 如果返回的数据为空数组，则使用临时数据
      setLifeItems(Array.isArray(data) && data.length > 0 ? data : lifeItems);
    } catch (error) {
      console.error('Error fetching life data:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCarouselItems((prevItems) => {
        const newItems = [...prevItems];
        const firstItem = newItems.shift();
        newItems.push(firstItem);
        return newItems;
      });
    }, 3000);
    return () => clearInterval(interval);
  }, [carouselItems]);

  return (
    <div className="wrapper">
      <div className="container">
        <div className="carousel">
          {carouselItems.map((item, index) => (
            item && item.src && (
              <img
                key={index}
                src={item.src}
                alt={item.alt || 'carousel image'}
                className={index === 0 ? 'active' : ''}
              />
            )
          ))}
        </div>
        <div className="section" id="section01">
          <h2>追剧<a href="#" className="more">更多</a></h2>
          <ul id="drama-list">
            {dramaItems.map((item, index) => (
              <li key={index}>
                {item.icon} <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="section" id="section02">
          <h2>生活<a href="#" className="more">更多</a></h2>
          <ul id="life-list">
            {lifeItems.map((item, index) => (
              <li key={index}>
                {item.icon} <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="tabs">
          <a href="#">常用</a>
          <a href="#">工具</a>
          <a href="#">待定</a>
        </div>
      </div>
      <div className="footer">
        <img src="http://q1.qlogo.cn/g?b=qq&nk=734330435&s=640" alt="Profile" />
        <div className="jinrishici">
          <span id="jinrishici-sentence">正在加载今日诗词....</span>
        </div>
      </div>
    </div>
  );
}

export default App;